<template>
  <div class="page">
    <webHeader :currentIndex="currentIndex"></webHeader>
    <div class="centerMain">
      <div class="centerLeft">
        <div class="titleTab">
          <span
            @click="onTitleTabClick(item, index)"
            class="pointer"
            :class="titleTabPos == index ? 'titleTabItemTo' : 'titleTabItem'"
            v-for="(item, index) in productlist"
            :key="index"
          >
            {{ item.from }}
          </span>
        </div>
        <div style="width: 70vw;">
          <echarts :isShow="false" :type="type" :currentprice="currentprice" />
        </div>
        <div class="tabV">
          <div
            :class="tabPos == 0 ? 'tabVItem' : 'tabVItemTo'"
            class="pointer"
            @click="onTabClick(0)"
          >
            {{ $t("trade.desc") }}
          </div>
          <div
            :class="tabPos == 1 ? 'tabVItem' : 'tabVItemTo'"
            class="pointer"
            @click="onTabClick(1)"
          >
            {{ $t("trade.deal") }}
          </div>
        </div>
        <div v-if="tabPos == 0" style="background-color: #17181b; ">
          <van-row class="brief">
            <van-row class="brief-publish">
              <h3>{{ detail.product_title }}</h3>
            </van-row>
            <van-row class="brief-publish">
              <van-col :span="12" style="text-align: left;">
                <span>{{ $t("trade.time") }}</span>
              </van-col>
              <van-col :span="12" style="text-align: right;"
                ><span>{{ detail.publishtime }}</span>
              </van-col>
            </van-row>
            <van-row class="brief-publish">
              <van-col :span="12" style="text-align: left;">
                <span>{{ $t("trade.number") }}</span>
              </van-col>
              <van-col :span="12" style="text-align: right;"
                ><span>{{ detail.publishnum }}</span>
              </van-col>
            </van-row>
            <van-row class="brief-publish" style="border-bottom:none">
              <h3>{{ $t("trade.desc") }}</h3>
              <span v-if="lang === 'en'">
                {{ detail.en_description }}
              </span>
              <span v-if="lang === 'ru'">
                {{ detail.ru_description }}
              </span>
              <span v-if="lang === 'zh'">
                {{ detail.cn_description }}
              </span>
              <span v-if="lang === 'fr'">
                {{ detail.fr_description }}
              </span>
              <span v-if="lang === 'it'">
                {{ detail.it_description }}
              </span>
              <span v-if="lang === 'es'">
                {{ detail.es_description }}
              </span>
            </van-row>
          </van-row>
        </div>
        <div v-if="tabPos == 1" style="background-color: #17181b; max-height: 50vh; overflow: hidden; overflow-y: scroll;">
          <van-row class="noDate" v-if="completedorder.length == 0">
            <van-empty />
          </van-row>
          <van-row v-else>
            <van-row
              class="currency-Increase"
              v-for="item in completedorder"
              :key="item.id"
            >
              <van-col :span="8" style="text-align:left">
                <span >
                  <span v-if="item.type == '1'" style="color:#14b95c">
                    {{ $t("trade.more") }}
                    <van-image
                      width="1.5rem"
                      height="1rem"
                      :src="require('../../../assets/img/jtk.png')"
                    />
                  </span>
                  <span v-else style="color:#f6185b">
                    {{ $t("trade.empty") }}
                    <van-image
                      width="1.5rem"
                      height="1rem"
                      :src="require('../../../assets/img/jtd.png')"
                    />
                  </span>
                  <br />
                  <h5
                    style="color: #aeb9d8; margin-top: 10px; font-size: 16px;"
                  >
                    {{ item.product_name }}
                  </h5>
                </span>
              </van-col>
              <van-col :span="8">
                <span style="color: #f6185b">{{ item.profit }}</span>
              </van-col>
              <van-col :span="8" class="currency-time">
                <span>
                  <span>
                    {{ $t("trade.amount") }}<br />
                    {{ item.total_price }}
                  </span>
                </span>
                <br />
                <span>
                  {{ $t("trade.jian") }} <br />
                  {{ item.open_price }}
                </span>
                <br />
                <span>
                  {{ $t("trade.ping") }} <br />
                  {{ item.end_price }}
                </span>
              </van-col>
            </van-row>
            <!-- <van-row style="margin: 20px;color: #aeb9d8;">{{
              $t("common.nomore")
            }}</van-row> -->
          </van-row>
        </div>
      </div>
      <div class="centerRight">
        <div class="infoV">
          <span class="infoName">{{ type.toUpperCase() }}</span>
          <div
            style="width: 100%; display: flex; align-items: center;margin-top: 10px;"
          >
            <div style="flex: 6;">
              <h1
                style="font-size: 25px;"
                :style="{
                  color: pagedata.change > 0 ? '#14b95c' : '#f6185b',
                }"
              >
                {{ pagedata.current }}
              </h1>
              <!-- <span
                style="margin-top: 3px;font-size: 14px;"
                :style="{
                  color: pagedata.change > 0 ? '#14b95c' : '#f6185b',
                }"
              >
                ≈{{ pagedata.cny }} CNY {{ pagedata.change }}%
              </span> -->
            </div>
            <div style="flex: 4;">
              <div v-if="tradeData">
                <span class="textTv1"
                  >{{ $t("trade.high") }} {{ tradeData.high }}</span
                >
              </div>
              <div v-if="tradeData">
                <span class="textTv1"
                  >{{ $t("trade.low") }} {{ tradeData.low }}</span
                >
              </div>
              <div v-if="tradeData">
                <span class="textTv1">24h {{ tradeData.volume }}</span>
              </div>
            </div>
          </div>
        </div>
        <van-row class="text">
          <van-row>
            <van-row
              v-if="detail && detail.times"
              class="takeProfitV"
              style="margin-top: 10px;"
            >
              <div class="takeProfitVV">
                <div
                  :class="
                    mLeverPos1 == indexProfit
                      ? 'takeProfitItemTo'
                      : 'takeProfitItem'
                  "
                  v-for="(itemProfit, indexProfit) in detail.times"
                  @click="onItemProfit1Click(itemProfit, indexProfit)"
                  class="pointer"
                >
                <span class="takeProfitItemTv1">
                    {{ itemProfit }}S
                </span>
                <span :style="mLeverPos1 == indexProfit ?'background-color: #fff;':'background-color: #f0b90a;'" style="width: 50px; height: 1px; "></span>
                <span class="takeProfitItemTv1">
                    {{ detail.ratio[indexProfit] }}%
                </span>
              </div>
              </div>
            </van-row>
            <div
              style="padding-top: 10px; align-items: center; display: flex; width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
            >
              <span class="moneyTv1">
                {{ $t("trade.fangx") }}
              </span>
              <div class="directionV">
                <span
                  :class="
                    tradedata.type == 1
                      ? 'directionTv1To'
                      : 'directionTv1'
                  "
                  @click="onDirectionClick(1)"
                  class="pointer"
                  >{{ $t("trade.long") }}
                </span>
                <span
                  :class="
                    tradedata.type == 2
                      ? 'directionTv2To'
                      : 'directionTv2'
                  "
                  @click="onDirectionClick(2)"
                  class="pointer"
                  >{{ $t("trade.short") }}
                </span>
              </div>
            </div>
            <div class="moneyV" style="margin-top: 15px;">
              <span class="moneyTv1">{{ $t("shop.number") }}</span>
              <div class="moneyInput">
                <van-field
                  v-model="tradedata.total"
                  :placeholder="$t('trade.input')"
                />
              </div>
              <span class="moneyTv2">
                {{ $t("trade.usable") }}:{{ tradedata.balance }}
              </span>
            </div>
            <div v-if="detail&&detail.minimum"
              style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
            >
              <span class="moneyTv1">
                {{ $t("trade.min") }}:{{ detail.minimum[mLeverPos1] }}
              </span>
            </div>
            <div
              style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
            >
              <span class="moneyTv1">
                {{ $t("trade.fee") }}:{{ tradedata.fee }}
              </span>
            </div>
          </van-row>
          <van-button @click="onSubmitClick">
            {{ $t("common.confirm") }}
          </van-button>
        </van-row>
        <div class="tabV tabVItemTo" style="margin-left: 10px;">
          {{ $t("trade.weit") }}
        </div>
        <div style="background-color: #17181b; margin-left: 10px; max-height: 50vh; overflow: hidden; overflow-y: scroll;">
          <van-row
            class="currency-Increase"
            v-for="item in progressorder"
            :key="item.id"
          >
            <van-col :span="8" style="text-align:left">
              <span>
                <span v-if="item.type == '1'" style="color:#14b95c">
                  {{ $t("trade.more") }}
                  <van-image
                    width="1.5rem"
                    height="1rem"
                    :src="require('../../../assets/img/jtk.png')"
                  />
                </span>
                <span v-else style="color:#f6185b">
                  {{ $t("trade.empty") }}
                  <van-image
                    width="1.5rem"
                    height="1rem"
                    :src="require('../../../assets/img/jtd.png')"
                  />
                </span>
                <br />
                <h5 style="color: #aeb9d8; margin-top: 10px; font-size: 16px;">
                  {{ item.product_name }}
                </h5>
              </span>
            </van-col>
            <van-col :span="8">
              <span style="color: #f6185b;">{{ item.profit }}</span>
            </van-col>
            <van-col :span="8" class="currency-time">
              <span>
                <span>
                  {{ $t("trade.amount") }}<br />
                  {{ item.total_price }}
                </span>
              </span>
              <br />
              <span>
                {{ $t("trade.jian") }} <br />
                {{ item.open_price }}
              </span>
              <br />
              <span>
                {{ $t("trade.ping") }} <br />
                {{ item.end_price }}
              </span>
            </van-col>
          </van-row>
         
          <van-row
            class="noDate"
            v-if="progressorder && progressorder.length == 0"
          >
            <van-empty  />
          </van-row>
        </div>
      </div>
    </div>
    <van-popup
      :close-on-click-overlay="false"
      v-model="showDialog"
      style="background-color:transparent; overflow:hidden;"
    >
      <div class="loadingWrap">
        <van-loading v-if="showDialog" type="spinner" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import echarts from "@/components/echarts.vue";
import webHeader from "@/components/web/webHeader.vue";
export default {
  components: { webHeader, echarts },
  data() {
    return {
	  currentIndex:2,
      titleTabPos: 0,
      lang: localStorage.getItem("lang") || "en",
      tabPos: 0,
      detail: {}, //产品详情
      type: "",
      productid: "",
      currentprice: "", // 当前价格
      pagedata: {}, //页面数据
      productlist: [],
      tradeData: null,
      // 买多买空数据对象
      tradedata: {
        type: 1, // 买多，买空
        price: 10, // 当前价格
        fee: 0, // 手续费
        balance: 0, // 可用余额
      },
      mLever: null, //杠杆数据
      mLeverPos1: 0,
      mLeverPos2: 0,
      mLeverPos3: 0,
      showDialog: false,
      identity: "",
      completedorder: [], //交易记录
      progressorder: [],
    };
  },
  created() {
    this.getList();
    this.getinfo();
  },
  methods: {
    onTitleTabClick(item, index) {
      this.titleTabPos = index;
      let name = item.from;
      this.type = name.toLowerCase();
      this.productid = item.pid;
      this.getdata();
      this.getdetail();
      this.getinfo();
      this.getGGlist();
    },
    async getinfo() {
      const { data } = await this.$http.get("/home/home/getindentity");
      if (data) {
        if (data.code === 200) {
          this.identity = data.data;
        }
      }
    },
    // 打开货币
    async getList() {
      this.isshow = true;
      // 获取自选和产品列表
      const { data } = await this.$http.get("/home/trade/getproduct");
      if (data) {
        if (data.code === 200) {
          this.productlist = data.data.productlist;
          let name = this.productlist[0].from;
          this.type = name.toLowerCase();
          this.productid = this.productlist[0].pid;
          this.getdata();
          this.getdetail();
          this.getGGlist();
        }
      }
    },
    // 获取产品详情
    async getdetail() {
      const { data } = await this.$http.get(
        "/home/trade/getdetail/productid/" + this.productid
      );
      if (data) {
        if (data.code === 200) {
          this.detail = data.data;
          this.tradedata.total = this.detail.minimum[0];
          this.gettradeto(data.data.product_code);
          this.tradeopen();
        } else {
          this.$toast.error(this.$t("common.fail"));
        }
      }
    },
    //详情
    async gettradeto(productCode) {
      
      const { data } = await this.$http.get(
        "/home/trade/gettradeto/type/" + productCode
      );
      if (data) {
        if (data.code === 200) {
          this.tradeData = data.data[0];
        } else {
          this.$toast.error(this.$t("common.fail"));
        }
      }
    },
    // 获取行情最新数据
    getdata() {
      if(this.websocket){
        this.websocket.close();
      }
      this.websocket = new WebSocket(this.wsurl + "/wsss:2052");
      this.websocket.onopen = this.socketonopen;
      this.websocket.onmessage = this.socketonmessage;
    },
    socketonopen() {
      this.websocket.send(this.type);
    },
    socketonmessage(evt) {
      this.pagedata = JSON.parse(evt.data);
      this.currentprice = this.pagedata.current;
    },
    async onDirectionClick(type) {
      this.tradedata.type = type;
    },
    //选择秒数
    onItemProfit1Click(item, index) {
        this.mLeverPos1 = index;
      this.tradedata.times = this.detail.times[index];
      this.tradedata.ratio = this.detail.ratio[index];
    },
    // 期货交易详情
    async tradeopen() {
      // 判断产品下单时间并获取收费费和可用余额
      const { data } = await this.$http.get(
        "/home/trade/befororder/productid/" + this.productid
      );
      if (data) {
        if (data.code === 200) {
          this.mLeverPos1 = 0;
          this.tradedata.type = 1;
          this.tradedata.times = this.detail.times[0];
          this.tradedata.ratio = this.detail.ratio[0];
          this.tradedata.fee = data.data.fee;
          this.tradedata.balance = data.data.balance;
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')));
          return false;
        }
      }

      // this.tradedata.price = this.currentprice;
      this.tradedata.productid = this.productid;
      this.tradedata.product_title = this.detail.product_title;
      this.tradeshow = true;
    },
    // 提交杠杆订单
    async onSubmitClick() {
      // if (this.identity.oauth < 4) {
      //   this.$toast.fail(this.$t("trade.shiming"));
      //   return false;
      // }
      if (!this.tradedata.times) {
        this.$toast.fail(this.$t("trade.selecttime"));
        return false;
      }
      if (!this.tradedata.total) {
        this.$toast.fail(this.$t("trade.input"));
        return false;
      }
      if (
        +this.tradedata.total < +this.detail.minimum[this.isactive] ||
        +this.tradedata.total > +this.detail.maximum[this.isactive]
      ) {
        this.$toast.fail(this.$t("trade.error"));
        return false;
      }
      if (+this.tradedata.total > +this.tradedata.balance) {
        this.$toast.fail(this.$t("trade.error1"));
        return false;
      }
      this.showDialog = true;
      this.tradedata.price = this.currentprice;

      const { data } = await this.$http.post(
        "/home/trade/order",
        this.tradedata
      );
      if (data) {
        if (data.code === 200) {
          this.$toast.success(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
          this.tradeopen();
          this.getGGlist();
          this.getJYList();
        } else {
          this.$toast.fail(this.$t(data.msg));
        }
      }
      this.showDialog = false;
    },
    onTabClick(e) {
      //
      this.tabPos = e;
      if (this.tabPos == 1) {
        this.getJYList();
      }
    },
    //获取交易记录
    async getJYList() {
      const { data } = await this.$http.get("/home/trade/get_orders");
      if (data) {
        if (data.code === 200) {
          this.completedorder = data.data;
        }
      }
    },
    //获取期货订单
    async getGGlist() {
      const { data } = await this.$http.get(
        "/home/trade/orderlist/productid/" + this.productid + "/status/" + "999"
      );
      if (data) {
        if (data.code === 200) {
          this.progressorder = data.data;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background-color: #141416;
  min-height: 100vh;
}
.centerMain {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100vw;
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.centerLeft {
  width: 70vw;
  display: flex;
  flex-direction: column;
}

.centerRight {
  width: 30vw;
  width: 100%;
  overflow: hidden;
}
.tabV {
  background: #24262c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tabVItem {
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0b90a;
  padding-left: 10px;
  padding-right: 10px;
}

.tabVItemTo {
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #00000000;
  padding-left: 10px;
  padding-right: 10px;
}

.infoV {
  padding: 10px;
  background: #24262c;
  margin-left: 10px;
  width: 100%;
  overflow: hidden;
}
.infoName {
  color: #fff;
  font-size: 17px;
}
.text {
  width: 100%;
  height: 395px;
  overflow: hidden;
}
.textTv1 {
  color: #aeb9d8;
  font-size: 12px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3px;
}
.moneyV {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 13px;
  margin: 10px 0px;
}
.moneyTv1 {
  color: #aeb9d8;
  font-size: 14px;
  width: 60px;
  text-align: left;
}
.moneyInput {
  width: 120px;
  background-color: #e7e6e9;
  border-radius: 10px;
  margin-left: 25px;
  .van-cell {
    background-color: #e7e6e9;
    border-radius: 5px;
  }
}
.moneyTv2 {
  color: #aeb9d8;
  font-size: 14px;
  margin-left: 10px;
}
.directionV {
  display: flex;
  align-items: center;
  margin-left: 25px;
  border-radius: 5px;
  background-color: #edeff5;
  width: fit-content;
}
.directionTv1 {
  background-color: #333;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 14px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.directionTv1To {
  background-color: #f0b90a;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 14px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.directionTv2 {
  background-color: #333;
  color: #fff;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.directionTv2To {
  background-color: #f0b90a;
  color: #fff;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.takeProfitV {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}
.takeProfitTv {
  color: #aeb9d8;
  font-size: 14px;
  width: 80px;
  text-align: left;
  max-width: 80px;
}
.takeProfitVV {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  overflow-x: auto;
}
.takeProfitItem {
  color: #fff;
  border: 1px solid #f0b90a;
  background-color: #00000000;
  width: 60px;
  min-width: 60px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: 500;
  font-size: 13px;
}
.takeProfitItemTo {
  color: #fff;
  border: 0.1px solid #f0b90a;
  background-color: #f0b90a;
  width: 60px;
  min-width: 60px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: 500;
  font-size: 13px;
}
.takeProfitItemTv1{
    padding-top: 3px;
    padding-bottom: 3px;
}

.van-button {
  width: 90%;
  height: 2.5rem;
  background-color: #f0b90a;
  border-radius: 1px solid #f0b90a;
  color: #fff;
}
.van-button--default {
  border: 1px solid #f0b90a;
}

// 简介
.brief {
  padding: 1.06667rem;
  text-align: left;

  .brief-publish {
    padding-left: 0.53333rem;
    line-height: 2.93333rem;
    border-bottom: 0.5px solid #b7bbc4;

    h3 {
      color: #aeb9d8;
      font-size: 1rem;
    }
  }

  span {
    font-size: 0.93333rem;
    color: #aeb9d8;
  }
}

.currency-Increase {
  color: #aeb9d8;
  padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  line-height: 1.6rem;
  border-bottom: 0.5px solid #b7bbc4;
}

.currency-time {
  font-size: 0.8rem;
  color: #aeb9d8;
  text-align: right;
}

/deep/.van-tabs__nav {
  background-color: #24262c;
}
.van-tab--active {
  color: #fff;
}
.titleTab {
  background-color: #24262c;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.titleTabItem {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 15px;
  background-color: #00000000;
}
.titleTabItemTo {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 15px;
  background-color: #f0b90a;
  border-radius: 3px;
}
</style>
